export const urlFiles = {
  productImage: `${process.env.REACT_APP_API_URL}/foto-producto`,
  productFile: `${process.env.REACT_APP_API_URL}/archivo-producto`,
}

export const urlPdfs = {
  ventaTicket: `${process.env.REACT_APP_API_URL}/api/ticketventa`,
  ventaA4: `${process.env.REACT_APP_API_URL}/api/pdfventaa4`,
  reporteVentasA4: `${process.env.REACT_APP_API_URL}/api/pdf-reporte-ventas-a4`,
  notaCreditoA4: `${process.env.REACT_APP_API_URL}/api/pdfnotacredito`,
  proformaTicket: `${process.env.REACT_APP_API_URL}/api/pproforma`,
  proformaA4: `${process.env.REACT_APP_API_URL}/api/pproformaa4`,
  pedidoTicket: `${process.env.REACT_APP_API_URL}/api/ppedido`,
  cajaChicaTicket: `${process.env.REACT_APP_API_URL}/api/vouchercajachica`,
  notaPagoTicket: `${process.env.REACT_APP_API_URL}/api/printpdfcpago`,
  guiaA4: `${process.env.REACT_APP_API_URL}/api/pdfguiaa4`,
  entradaA4: `${process.env.REACT_APP_API_URL}/api/pdfentradaa4`,
  salidaA4: `${process.env.REACT_APP_API_URL}/api/pdfsalidaa4`,
  ordenServicioA4: `${process.env.REACT_APP_API_URL}/api/pdfordenservicioa4`,
  inventarioOrdenServicioA4: `${process.env.REACT_APP_API_URL}/api/pdfinventarioordenservicioa4`,
  prestamoTicket: `${process.env.REACT_APP_API_URL}/api/ticketprestamo`,
  prestamoCajaChicaTicket: `${process.env.REACT_APP_API_URL}/api/ticketprestamocajachica`,
  reportePrestamosClienteA4: `${process.env.REACT_APP_API_URL}/api/reporteprestamosclientea4`,
  pagoCuotaPrestamoTicket: `${process.env.REACT_APP_API_URL}/api/ticketpagocuotaprestamo`,
  reclamacionA4: `${process.env.REACT_APP_API_URL}/api/pdfreclamaciona4`,
}   
/**
 * 
 * @param {any} value
 * @param {object} options
 * @param {number} options.minDecimals
 * @param {number} options.maxDecimals
 * @param {string} options.locale
 * @param {boolean} options.isMoney
 * @param {string} options.style
 * @param {string} options.currency
 *  
 * @returns {string}
 */
export const formatDecimals = (
  value = 0,
  options = {}
) => {

  const defaultOptions = {
    minDecimals: 2,
    maxDecimals: 4,
    locale: 'es-PE',
    isMoney: false,
    style: 'currency',
    currency: 'PEN'
  };

  const { minDecimals, maxDecimals, locale, isMoney, style, currency } = {
    ...defaultOptions,
    ...options
  }

  const formatter = new Intl.NumberFormat(locale, {
    style: isMoney ? style : undefined,
    currency: isMoney ? currency : undefined,
    minimumFractionDigits: minDecimals,
    maximumFractionDigits: maxDecimals
  })

  return formatter.format(value)
}
import axios from 'axios';
import { toast } from 'react-toastify';
import * as Actions from 'components/auth/store/actions';

export const CPE_ACTION = '[MANAGE CPE] CPE_ACTION';

export const CONSUlTA_COMPROBANTE_SUNAT =
  '[MANAGE CPE] CONSULTA COMPROBANTE SUNAT';

export const ERROR_CPE = '[MANAGE CPE] ERROR CPE';

// VENTAS:

export function generateSale({ idventa }) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/cpe/venta/generar`,
    { idventa }
  );
  return (dispatch) => {
    dispatch({ type: CPE_ACTION, payload: { loading: true } });
    toast.info('Generando...', { toastId: 'mssgCpe', autoClose: false });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (response.data.status == 200) {
          dispatch({
            type: CPE_ACTION,
            payload: { loading: false, action: true },
          });
          toast.update('mssgCpe', {
            render: response.data.mensaje,
            type: response.data.type,
            autoClose: 5000,
          });
        } else {
          dispatch({
            type: CPE_ACTION,
            payload: { loading: false, action: false },
          });
          toast.update('mssgCpe', {
            render: response.data.mensaje,
            type: response.data.type,
            autoClose: 5000,
          });
        }
        return dispatch({
          type: CPE_ACTION,
          payload: { loading: false, action: false },
        });
      })
      .catch((error) => {
        toast.update('mssgCpe', {
          render: error.message,
          type: 'error',
          autoClose: 5000,
        });
        return dispatch({
          type: CPE_ACTION,
          payload: { loading: false, action: false },
        });
      });
  };
}

export function signSale({ idventa }) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/cpe/venta/firmar`,
    { idventa }
  );
  return (dispatch) => {
    dispatch({ type: CPE_ACTION, payload: { loading: true } });
    toast.info('Firmando...', { toastId: 'mssgCpe', autoClose: false });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (response.data.status == 200) {
          dispatch({
            type: CPE_ACTION,
            payload: { loading: false, action: true },
          });
          toast.update('mssgCpe', {
            render: response.data.mensaje,
            type: response.data.type,
            autoClose: 5000,
          });
        } else {
          console.log(response.data.detalles);
          toast.update('mssgCpe', {
            render: response.data.mensaje,
            type: response.data.type,
            autoClose: 5000,
          });
        }
        return dispatch({
          type: CPE_ACTION,
          payload: { loading: false, action: false },
        });
      })
      .catch((error) => {
        toast.update('mssgCpe', {
          render: error.message,
          type: 'error',
          autoClose: 5000,
        });
        dispatch({
          type: CPE_ACTION,
          payload: { loading: false, action: false },
        });
      });
  };
}

export function sendSale({ idventa }) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/cpe/venta/enviar`,
    { idventa }
  );
  return (dispatch) => {
    dispatch({ type: CPE_ACTION, payload: { loading: true } });
    toast.info('Enviando a SUNAT...', { toastId: 'mssgCpe', autoClose: false });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (response.data.status == 200) {
          dispatch({
            type: CPE_ACTION,
            payload: { loading: false, action: true },
          });
          toast.update('mssgCpe', {
            render: response.data.mensaje,
            type: response.data.type,
            autoClose: 5000,
          });
        } else {
          toast.update('mssgCpe', {
            render: response.data.mensaje,
            type: response.data.type,
            autoClose: 5000,
          });
        }
        return dispatch({
          type: CPE_ACTION,
          payload: { loading: false, action: false },
        });
      })
      .catch((error) => {
        toast.update('mssgCpe', {
          render: error.message,
          type: 'error',
          autoClose: 5000,
        });
        return dispatch({
          type: CPE_ACTION,
          payload: { loading: false, action: false },
        });
      });
  };
}

export function reverceSale({ idventa }) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/cpe/venta/revertir`,
    { idventa }
  );
  return (dispatch) => {
    dispatch({ type: CPE_ACTION, payload: { loading: true } });
    toast.info('Revirtiendo...', { toastId: 'mssgCpe', autoClose: false });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }

        if (response.data.status == 200) {
          dispatch({
            type: CPE_ACTION,
            payload: { loading: false, action: true },
          });
          toast.update('mssgCpe', {
            render: response.data.mensaje,
            type: response.data.type,
            autoClose: 5000,
          });
        } else {
          dispatch({
            type: CPE_ACTION,
            payload: { loading: false, action: false },
          });
          console.log(response.data.detalles);
          toast.update('mssgCpe', {
            render: response.data.mensaje,
            type: response.data.type,
            autoClose: 5000,
          });
        }
      })
      .catch((error) => {
        toast.update('mssgCpe', {
          render: error.message,
          type: 'error',
          autoClose: 5000,
        });
        return dispatch({
          type: CPE_ACTION,
          payload: { loading: false, action: false },
        });
      });
  };
}

export function sendUninformedSales() {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/cpe/venta/enviar-no-informados`);
  return (dispatch) => {
    dispatch({ type: CPE_ACTION, payload: { loading: true } });
    toast.info('Enviando ventas a SUNAT, por favor espere...', { toastId: 'mssgCpe', autoClose: false });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (response.data.status == 200) {
          dispatch({
            type: CPE_ACTION,
            payload: { loading: false, action: true },
          });
          toast.update('mssgCpe', {
            render: response.data.mensaje,
            type: 'success',
            autoClose: 5000,
          });
        } else {
          toast.update('mssgCpe', {
            render: response.data.mensaje,
            type: 'error',
            autoClose: 5000,
          });
        }
        return dispatch({
          type: CPE_ACTION,
          payload: { loading: false, action: false },
        });
      })
      .catch((error) => {
        toast.update('mssgCpe', {
          render: 'Ups, algo salió mal en el servidor. Intente el envío manual',
          type: 'error',
          autoClose: 5000,
        });
        return dispatch({
          type: CPE_ACTION,
          payload: { loading: false, action: false },
        });
      });
  };
}

// GUIAS ELECTRONICAS:

export function generateGuide({ idguia }) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/cpe/guia/generar`,
    { idguia }
  );
  return (dispatch) => {
    dispatch({ type: CPE_ACTION, payload: { loading: true } });
    toast.info('Generando...', { toastId: 'mssgCpe', autoClose: false });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (response.data.status == 200) {
          dispatch({
            type: CPE_ACTION,
            payload: { loading: false, action: true },
          });
          toast.update('mssgCpe', {
            render: response.data.mensaje,
            type: response.data.type,
            autoClose: 5000,
          });
        } else {
          dispatch({
            type: CPE_ACTION,
            payload: { loading: false, action: false },
          });
          console.log(response.data.detalles);
          toast.update('mssgCpe', {
            render: response.data.mensaje,
            type: response.data.type,
            autoClose: 5000,
          });
        }
        dispatch({
          type: CPE_ACTION,
          payload: { loading: false, action: false },
        });
      })
      .catch((error) => {
        dispatch({
          type: CPE_ACTION,
          payload: { loading: false, action: false },
        });
        toast.update('mssgCpe', {
          render: error.message,
          type: 'error',
          autoClose: 5000,
        });
      });
  };
}

export function signGuide({ idguia }) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/cpe/guia/firmar`, {
    idguia,
  });
  return (dispatch) => {
    dispatch({ type: CPE_ACTION, payload: { loading: true } });
    toast.info('Firmando...', { toastId: 'mssgCpe', autoClose: false });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (response.data.status == 200) {
          dispatch({
            type: CPE_ACTION,
            payload: { loading: false, action: true },
          });
          toast.update('mssgCpe', {
            render: response.data.mensaje,
            type: response.data.type,
            autoClose: 5000,
          });
        } else {
          dispatch({
            type: CPE_ACTION,
            payload: { loading: false, action: false },
          });
          console.log(response.data.detalles);
          toast.update('mssgCpe', {
            render: response.data.mensaje,
            type: response.data.type,
            autoClose: 5000,
          });
        }
        return dispatch({ type: CPE_ACTION, payload: false });
      })
      .catch((error) => {
        toast.update('mssgCpe', {
          render: error.message,
          type: 'error',
          autoClose: 5000,
        });
        return dispatch({
          type: CPE_ACTION,
          payload: { loading: false, action: false },
        });
      });
  };
}

export function sendGuide({ idguia }) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/cpe/guia/enviar`, {
    idguia,
  });
  return (dispatch) => {
    dispatch({ type: CPE_ACTION, payload: { loading: true } });
    toast.info('Enviando a SUNAT...', { toastId: 'mssgCpe', autoClose: false });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (response.data.status == 200) {
          dispatch({
            type: CPE_ACTION,
            payload: { loading: false, action: true },
          });
          toast.update('mssgCpe', {
            render: response.data.mensaje,
            type: response.data.type,
            autoClose: 5000,
          });
        } else {
          console.log(response.data.detalles);
          toast.update('mssgCpe', {
            render: response.data.mensaje,
            type: response.data.type,
            autoClose: 5000,
          });
        }
        return dispatch({
          type: CPE_ACTION,
          payload: { loading: false, action: false },
        });
      })
      .catch((error) => {
        toast.update('mssgCpe', {
          render: error.message,
          type: 'error',
          autoClose: 5000,
        });
        return dispatch({
          type: CPE_ACTION,
          payload: { loading: false, action: false },
        });
      });
  };
}

export function reverceGuide({ idguia }) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/cpe/guia/revertir`,
    { idguia }
  );
  return (dispatch) => {
    dispatch({ type: CPE_ACTION, payload: { loading: true } });
    toast.info('Revirtiendo...', { toastId: 'mssgCpe', autoClose: false });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (response.data.status == 200) {
          dispatch({
            type: CPE_ACTION,
            payload: { loading: false, action: true },
          });
          toast.update('mssgCpe', {
            render: response.data.mensaje,
            type: response.data.type,
            autoClose: 5000,
          });
        } else {
          dispatch({
            type: CPE_ACTION,
            payload: { loading: false, action: false },
          });
          console.log(response.data.detalles);
          toast.update('mssgCpe', {
            render: response.data.mensaje,
            type: response.data.type,
            autoClose: 5000,
          });
        }
        return dispatch({
          type: CPE_ACTION,
          payload: { loading: false, action: false },
        });
      })
      .catch((error) => {
        toast.update('mssgCpe', {
          render: error.message,
          type: 'error',
          autoClose: 5000,
        });
        return dispatch({
          type: CPE_ACTION,
          payload: { loading: false, action: false },
        });
      });
  };
}

export function sendUninformedGuides() {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/cpe/guia/enviar-no-informados`);
  return (dispatch) => {
    dispatch({ type: CPE_ACTION, payload: { loading: true } });
    toast.info('Enviando guías a SUNAT, por favor espere...', { toastId: 'mssgCpe', autoClose: false });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (response.data.status == 200) {
          dispatch({
            type: CPE_ACTION,
            payload: { loading: false, action: true },
          });
          toast.update('mssgCpe', {
            render: response.data.mensaje,
            type: 'success',
            autoClose: 5000,
          });
        } else {
          toast.update('mssgCpe', {
            render: response.data.mensaje,
            type: 'error',
            autoClose: 5000,
          });
        }
        return dispatch({
          type: CPE_ACTION,
          payload: { loading: false, action: false },
        });
      })
      .catch((error) => {
        toast.update('mssgCpe', {
          render: 'Ups, algo salió mal en el servidor. Intente el envío manual',
          type: 'error',
          autoClose: 5000,
        });
        return dispatch({
          type: CPE_ACTION,
          payload: { loading: false, action: false },
        });
      });
  };
}

// NOTAS DE CREDITO:

export function generateCreditNote({ idnota }) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/cpe/nota-credito/generar`,
    { idnota }
  );
  return (dispatch) => {
    dispatch({ type: CPE_ACTION, payload: { loading: true } });
    toast.info('Generando...', { toastId: 'mssgCpe', autoClose: false });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (response.data.status == 200) {
          dispatch({
            type: CPE_ACTION,
            payload: { loading: false, action: true },
          });
          toast.update('mssgCpe', {
            render: response.data.mensaje,
            type: response.data.type,
            autoClose: 5000,
          });
        } else {
          dispatch({
            type: CPE_ACTION,
            payload: { loading: false, action: false },
          });
          console.log(response.data.detalles);
          toast.update('mssgCpe', {
            render: response.data.mensaje,
            type: response.data.type,
            autoClose: 5000,
          });
        }
        return dispatch({
          type: CPE_ACTION,
          payload: { loading: false, action: false },
        });
      })
      .catch((error) => {
        toast.update('mssgCpe', {
          render: error.message,
          type: 'error',
          autoClose: 5000,
        });
        return dispatch({
          type: CPE_ACTION,
          payload: { loading: false, action: false },
        });
      });
  };
}

export function signCreditNote({ idnota }) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/cpe/nota-credito/firmar`,
    { idnota }
  );
  return (dispatch) => {
    dispatch({ type: CPE_ACTION, payload: { loading: true } });
    toast.info('Firmando...', { toastId: 'mssgCpe', autoClose: false });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (response.data.status == 200) {
          dispatch({
            type: CPE_ACTION,
            payload: { loading: false, action: true },
          });
          toast.update('mssgCpe', {
            render: response.data.mensaje,
            type: response.data.type,
            autoClose: 5000,
          });
        } else {
          dispatch({
            type: CPE_ACTION,
            payload: { loading: false, action: false },
          });
          console.log(response.data.detalles);
          toast.update('mssgCpe', {
            render: response.data.mensaje,
            type: response.data.type,
            autoClose: 5000,
          });
        }
        return dispatch({
          type: CPE_ACTION,
          payload: { loading: false, action: false },
        });
      })
      .catch((error) => {
        toast.update('mssgCpe', {
          render: error.message,
          type: 'error',
          autoClose: 5000,
        });
        return dispatch({
          type: CPE_ACTION,
          payload: { loading: false, action: false },
        });
      });
  };
}

export function sendCreditNote({ idnota }) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/cpe/nota-credito/enviar`,
    { idnota }
  );
  return (dispatch) => {
    dispatch({ type: CPE_ACTION, payload: { loading: true } });
    toast.info('Enviando a SUNAT...', { toastId: 'mssgCpe', autoClose: false });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (response.data.status == 200) {
          dispatch({
            type: CPE_ACTION,
            payload: { loading: false, action: true },
          });
          toast.update('mssgCpe', {
            render: response.data.mensaje,
            type: response.data.type,
            autoClose: 5000,
          });
        } else {
          dispatch({
            type: CPE_ACTION,
            payload: { loading: false, action: false },
          });
          console.log(response.data.detalles);
          toast.update('mssgCpe', {
            render: response.data.mensaje,
            type: response.data.type,
            autoClose: 5000,
          });
        }
        return dispatch({
          type: CPE_ACTION,
          payload: { loading: false, action: false },
        });
      })
      .catch((error) => {
        toast.update('mssgCpe', {
          render: error.message,
          type: 'error',
          autoClose: 5000,
        });
        return dispatch({
          type: CPE_ACTION,
          payload: { loading: false, action: false },
        });
      });
  };
}

export function reverceCreditNote({ idnota }) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/cpe/nota-credito/revertir`,
    { idnota }
  );
  return (dispatch) => {
    dispatch({ type: CPE_ACTION, payload: { loading: true } });
    toast.info('Revirtiendo...', { toastId: 'mssgCpe', autoClose: false });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }

        if (response.data.status == 200) {
          dispatch({
            type: CPE_ACTION,
            payload: { loading: false, action: true },
          });
          toast.update('mssgCpe', {
            render: response.data.mensaje,
            type: response.data.type,
            autoClose: 5000,
          });
        } else {
          dispatch({
            type: CPE_ACTION,
            payload: { loading: false, action: false },
          });
          console.log(response.data.detalles);
          toast.update('mssgCpe', {
            render: response.data.mensaje,
            type: response.data.type,
            autoClose: 5000,
          });
        }
      })
      .catch((error) => {
        toast.update('mssgCpe', {
          render: error.message,
          type: 'error',
          autoClose: 5000,
        });
        return dispatch({
          type: CPE_ACTION,
          payload: { loading: false, action: false },
        });
      });
  };
}

export function sendUninformedCreditNotes() {
  const request = axios.post(`${process.env.REACT_APP_API_URL}/api/cpe/nota-credito/enviar-no-informados`);
  return (dispatch) => {
    dispatch({ type: CPE_ACTION, payload: { loading: true } });
    toast.info('Enviando notas de crédito a SUNAT, por favor espere...', { toastId: 'mssgCpe', autoClose: false });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (response.data.status == 200) {
          dispatch({
            type: CPE_ACTION,
            payload: { loading: false, action: true },
          });
          toast.update('mssgCpe', {
            render: response.data.mensaje,
            type: 'success',
            autoClose: 5000,
          });
        } else {
          toast.update('mssgCpe', {
            render: response.data.mensaje,
            type: 'error',
            autoClose: 5000,
          });
        }
        return dispatch({
          type: CPE_ACTION,
          payload: { loading: false, action: false },
        });
      })
      .catch((error) => {
        toast.update('mssgCpe', {
          render: 'Ups, algo salió mal en el servidor. Intente el envío manual',
          type: 'error',
          autoClose: 5000,
        });
        return dispatch({
          type: CPE_ACTION,
          payload: { loading: false, action: false },
        });
      });
  };
}

// CONSULTAS Y ENVIOS POR CORREO:

export function consultSale(saleId) {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}/api/cpe/venta/consultar/${saleId}`
  );
  return (dispatch) => {
    toast.info('Consultando en SUNAT...', {
      toastId: 'consultSale',
      autoClose: false,
    });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (response.data.status == 200) {
          toast.update('consultSale', {
            render: response.data.mensaje,
            type: 'success',
            autoClose: 5000,
          });
        } else {
          toast.update('consultSale', {
            render: response.data.mensaje,
            type: 'error',
            autoClose: 5000,
          });
        }
      })
      .catch((error) => {
        return toast.update('consultSale', {
          render: error.message,
          type: 'error',
          autoClose: 5000,
        });
      });
  };
}

export function sendSaleByMail(form) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/cpe/venta/enviar-por-correo`,
    form
  );
  return (dispatch) => {
    toast.info('Enviando comprobante por correo...', {
      toastId: 'mssgCpe',
      autoClose: false,
    });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (response.data.status == 200) {
          toast.update('mssgCpe', {
            render: response.data.mensaje,
            type: 'success',
            autoClose: 5000,
          });
        } else {
          console.log(response.data.detalles);
          toast.update('mssgCpe', {
            render: response.data.mensaje,
            type: 'error',
            autoClose: 5000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        return toast.update('mssgCpe', {
          render: error.message,
          type: 'error',
          autoClose: 5000,
        });
      });
  };
}

export function consultGuide(guideId) {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}/api/cpe/guia/consultar/${guideId}`
  );
  return (dispatch) => {
    toast.info('Consultando en SUNAT...', {
      toastId: 'consultGuide',
      autoClose: false,
    });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (response.data.status == 200) {
          toast.update('consultGuide', {
            render: response.data.mensaje,
            type: 'success',
            autoClose: 5000,
          });
        } else {
          toast.update('consultGuide', {
            render: response.data.mensaje,
            type: 'error',
            autoClose: 5000,
          });
        }
      })
      .catch((error) => {
        return toast.update('consultGuide', {
          render: error.message,
          type: 'error',
          autoClose: 5000,
        });
      });
  };
}

export function sendGuideByMail(form) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/cpe/guia/enviar-por-correo`,
    form
  );
  return (dispatch) => {
    toast.info('Enviando comprobante por correo...', {
      toastId: 'mssgCpe',
      autoClose: false,
    });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (response.data.status == 200) {
          toast.update('mssgCpe', {
            render: response.data.mensaje,
            type: 'success',
            autoClose: 5000,
          });
        } else {
          console.log(response.data.detalles);
          toast.update('mssgCpe', {
            render: response.data.mensaje,
            type: 'error',
            autoClose: 5000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        return toast.update('mssgCpe', {
          render: error.message,
          type: 'error',
          autoClose: 5000,
        });
      });
  };
}

export function consultCreditNote(noteId) {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}/api/cpe/nota-credito/consultar/${noteId}`
  );
  return (dispatch) => {
    toast.info('Consultando en SUNAT...', {
      toastId: 'consultCreditNote',
      autoClose: false,
    });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (response.data.status == 200) {
          toast.update('consultCreditNote', {
            render: response.data.mensaje,
            type: 'success',
            autoClose: 5000,
          });
        } else {
          toast.update('consultCreditNote', {
            render: response.data.mensaje,
            type: 'error',
            autoClose: 5000,
          });
        }
      })
      .catch((error) => {
        return toast.update('consultCreditNote', {
          render: error.message,
          type: 'error',
          autoClose: 5000,
        });
      });
  };
}

export function sendCreditNoteByMail(form) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/cpe/nota-credito/enviar-por-correo`,
    form
  );
  return (dispatch) => {
    toast.info('Enviando comprobante por correo...', {
      toastId: 'mssgCpe',
      autoClose: false,
    });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (response.data.status == 200) {
          toast.update('mssgCpe', {
            render: response.data.mensaje,
            type: 'success',
            autoClose: 5000,
          });
        } else {
          console.log(response.data.detalles);
          toast.update('mssgCpe', {
            render: response.data.mensaje,
            type: 'error',
            autoClose: 5000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        return toast.update('mssgCpe', {
          render: error.message,
          type: 'error',
          autoClose: 5000,
        });
      });
  };
}

// OTROS

export function consultErrorCpe() {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}/api/cpe/todos/obtener-no-informados`
  );
  return (dispatch) => {
    dispatch({ type: ERROR_CPE, payload: { loading: true } });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        return dispatch({ type: ERROR_CPE, payload: { loading: false, data: response.data.detalles } });
      })
      .catch((error) => {
        dispatch({
          type: ERROR_CPE,
          payload: {
            loading: false,
            data: { ventas: [], guias: [], notas: [] },
          },
        });

        toast.error(error.message);
      });
  };
}

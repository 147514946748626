import axios from 'axios';
import { toast } from 'react-toastify';
import * as Actions from 'components/auth/store/actions';

export const GET_INVENTORY_PRODUCTS =
  '[INVENTORY PRODUCTS] GET INVENTORY PRODUCTS';

export const CRUD_INVENTORY_PRODUCT =
  '[INVENTORY PRODUCTS] CRUD INVENTORY PRODUCT';

export function getInventoryProducts({ page = 1, storeId = 0 }) {
  const request = axios.get(
    `${process.env.REACT_APP_API_URL}/api/inventario/productos?page=${page}&idalmacen=${storeId}`
  );
  return (dispatch) => {
    dispatch({ type: GET_INVENTORY_PRODUCTS, payload: { loading: true } });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        dispatch({ type: GET_INVENTORY_PRODUCTS, payload: { loading: false, ...response.data } });
      })
      .catch((error) => {
        dispatch({
          type: GET_INVENTORY_PRODUCTS,
          payload: { loading: false },
        });
        toast.error('Error inesperado. No se pudo obtener los ingresos');
        console.log(error);
      });
  };
}

export function saveInventoryProduct(form) {
  const request = axios.post(
    `${process.env.REACT_APP_API_URL}/api/inventario/guardar-producto`,
    form
  );
  return (dispatch) => {
    dispatch({ type: CRUD_INVENTORY_PRODUCT, payload: { loading: true } });
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token');
            delete axios.defaults.headers.common['Authorization'];
            return dispatch(Actions.logoutUser());
          }
          return;
        }
        if (parseInt(response.data.status) === 200) {
          dispatch({
            type: CRUD_INVENTORY_PRODUCT,
            payload: { loading: false, crud: true },
          });
          toast.success(response.data.mensaje);
        } else {
          dispatch({
            type: CRUD_INVENTORY_PRODUCT,
            payload: { loading: false, crud: false },
          });
          toast.error(response.data.mensaje);
        }

        dispatch({
          type: CRUD_INVENTORY_PRODUCT,
          payload: { loading: false, crud: false },
        });
      })
      .catch((error) => {
        dispatch({
          type: CRUD_INVENTORY_PRODUCT,
          payload: { loading: false, crud: false },
        });
        toast.error('Error inesperado. No se pudo procesar la solicitud');
        console.log(error);
      });
  };
}
